// extracted by mini-css-extract-plugin
export var devopsAchievementsSection = "B_fg";
export var devopsBenefitSection = "B_d5";
export var devopsBusinessSection = "B_d3";
export var devopsDomainSection = "B_fc";
export var devopsExpertiseSection = "B_fb";
export var devopsProvideSection = "B_d6";
export var devopsResultsSection = "B_d7";
export var devopsSuccessStoriesSection = "B_fd";
export var devopsTestimonialPostSection = "B_d8";
export var devopsToolkitSection = "B_d9";
export var devopsVideoReviewSection = "B_fh";
export var devopsWhyChooseSection = "B_ff";
export var devopsWhyNeedSection = "B_d4";